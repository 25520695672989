import React, { useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signInWithRedirect, getRedirectResult, FacebookAuthProvider  } from "firebase/auth";
import { auth } from '../../firebase';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import ForgotPasswordModal from '../../components/misc/ForgotPasswordModal';

import "./login.scss";

const Login = () => {
  const navigate = useNavigate();
  const inputRef = useRef();
  const [userInput, setUserInput] = useState({
    email: '',
    password: ''
  })
  const [showPassword, setShowPassword] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState({
    successMsg: '',
    errorMsg: '',
  });


  const handleClickShowPassword = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const handleChange = (e) => {
    const newData = { ...userInput }
    newData[e.target.name] = e.target.value;
    setUserInput(newData);
    setResponseText("");
  }

  const handleLogin = (e) => {
    e.preventDefault();

    let email = userInput.email;
    let password = userInput.password;
    if (email && password) {
      setLoading(true);
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          setLoading(false);
          const user = userCredential.user;
          setResponseText({
            successMsg: 'Login Successful! Redirecting...',
            errorMsg: ''
          });
          // console.log(userCredential);
          setTimeout(() => {
            localStorage.setItem('token', JSON.stringify(user.stsTokenManager.accessToken));
            window.dispatchEvent(new Event("storage"));
            navigate("/");
          }, 1000);
        })
        .catch((error) => {
         
          setResponseText({ errorMsg: 'Incorrect Email / Password' });
          setLoading(false);
        });
    } else {
      setResponseText({ errorMsg: 'Please fill all fields' });
    }

  }
  
  const handleGoogleLogin = () => {
    const gprovider = new GoogleAuthProvider();
    signInWithPopup(auth, gprovider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      localStorage.setItem('token', JSON.stringify(user));
      window.dispatchEvent(new Event("storage"));
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);

    });
  }

  const handleFacebookLogin = () => {
    const fbprovider = new FacebookAuthProvider();
    fbprovider.setCustomParameters({
      'display': 'popup'
    });
    signInWithPopup(auth, fbprovider)
    .then((result) => {
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
    
      localStorage.setItem('token', JSON.stringify(user));
      window.dispatchEvent(new Event("storage"));
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = FacebookAuthProvider.credentialFromError(error);
    
    });
  }

  const forgotPasswordHandler = (e) => {
    e.preventDefault();
    setModal(true);
  }

  const closeModal = () => {
    setModal(false);
  }
 
  return (
    <>
    <div className="login-wrapper">
      <form onSubmit={handleLogin} className="login-box">
        <h4 className="login-title">Login</h4>
        <div className="input-group">
          <TextField
            name="email"
            onChange={handleChange}
            ref={inputRef}
            id="standard-input"
            label="Email"
            variant="standard"
          />
        </div>
        <div className="input-group">
          <FormControl variant="standard">
            <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
            <Input
              name="password"
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              value={userInput.password}
              onChange={handleChange}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </div>
        <div className="response">
          {responseText.successMsg &&
            <Alert style={{padding:'0 16px'}} className='alert-msg' severity="success">{responseText.successMsg}</Alert>
          }
          {responseText.errorMsg &&
            <Alert style={{padding:'0 16px'}} className='alert-msg' severity="error">{responseText.errorMsg}</Alert>
          }
        </div>
        <div className="login-btn">
          <Button type="submit" size="large" variant="contained"> {loading && <CircularProgress disableShrink />} Login</Button>
        </div>
        <div className="forgot">
          <a onClick={forgotPasswordHandler} href="#">Forgot Password?</a>
        </div>
        <div className="signin-options-wrap">
          <p>Sign in with </p>
          <div className="signin-options">
              <button type='button' onClick={handleGoogleLogin} className='google'><GoogleIcon />oogle</button>
              <span>&ndash; OR &ndash;</span>
              <button type='button' onClick={handleFacebookLogin} className='facebook'><FacebookIcon />acebook</button>
          </div>
        </div>
      </form>
    </div>
    <ForgotPasswordModal closeModal={closeModal} setModal={modal} />
    </>
  )
}

export default Login