import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './ui-block.scss';

const UIBlock = () => {
    return (
        <div className='ui-block'>
            <div className="inner">
                <div className="icon">
                    <CircularProgress />
                </div>
                <h4>LOGGING OUT</h4>
            </div>
        </div>
    )
}

export default UIBlock;
