import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Profile from "./pages/profile/Profile";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { productInputs, userInputs } from "./formSource";
import { DarkModeContext } from "./context/darkModeContext";
import "./style/dark.scss";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  
  const [ token, setToken ] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem('token'));
  }, [token])

  window.addEventListener('storage', () => {
    const changeToken = JSON.parse(window.localStorage.getItem('token'));
    setToken(changeToken);
  });

  return (
  
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        { token ? <>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="users">
              <Route index element={<List />} />
              <Route path=":userId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>
            <Route path="products">
              <Route index element={<List />} />
              <Route path=":productId" element={<Single />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            <Route path="profile" element={<Profile />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
          </>: 
        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="*" element={<Login />} />
          </Route>
        </Routes>
        }

      </BrowserRouter>
    </div>

  );
}

export default App;
