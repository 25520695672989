
import { initializeApp } from "firebase/app";
import { getAuth  } from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  // authDomain: "react-admin-panel-8efb5.firebaseapp.com",
  authDomain: "admin.prasid.com.np",
  databaseURL: "https://react-admin-panel-8efb5-default-rtdb.firebaseio.com",
  projectId: "react-admin-panel-8efb5",
  storageBucket: "react-admin-panel-8efb5.appspot.com",
  messagingSenderId: "101416804805",
  appId: "1:101416804805:web:7e10a5ee17c972831aebac"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);