import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { auth } from '../../firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import OtpInput from "react-otp-input";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2
};

const inputStyle = {
    width: 'calc(100% - 10px)',
    margin: '0 5px',
}

export default function ForgotPasswordModal(props) {

    const [userInput, setUserInput] = React.useState({
        phone_number: '',
    })

    const recaptchaWrapperRef = React.useRef(null);

    const [responseText, setResponseText] = React.useState({
        successMsg: '',
        errorMsg: '',
    });

    const [loading, setLoading] = React.useState(false);
    const [otpBlock, setOtpBlock] = React.useState(false);
    const [otpCode, setOtpCode] = React.useState('');
    const [counter, setCounter] = React.useState();

    React.useEffect(() => {
        const timer = counter > 0 && setInterval(() => {
            setCounter(counter - 1)
        }, 1000);

        if (counter < 1) {
            setResponseText({successMsg: '',errorMsg: ''})
        }

        return () => {
            clearInterval(timer); 
        };
    }, [counter])

    const handleChange = (e) => {
        // const newData = { ...userInput }
        // newData[e.target.name] = e.target.value;
        // setUserInput(newData);

        const { name, value } = e.target;
        setUserInput({
            [name]: value
        })
    }

    const handleOTP = (otp) => {
        setOtpCode(otp);
    }

    const configureCaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
                onSignInSubmit();
            }
        }, auth);
    }

    const onSignInSubmit = (e) => {
        e.preventDefault();
        const phoneNumber = userInput.phone_number;
        if (phoneNumber) {
            setLoading(true);
            configureCaptcha();
            const appVerifier = window.recaptchaVerifier;
            const phone = '+' + phoneNumber;

            signInWithPhoneNumber(auth, phone, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    setResponseText({
                        successMsg: 'OTP code has been sent to your mobile.',
                        errorMsg: ''
                    });
                    setLoading(false);
                    setOtpBlock(true);
                    setCounter(59);

                }).catch((error) => {
                    setLoading(false);
                    setResponseText({
                        successMsg: '',
                        errorMsg: 'Not valid. Please enter phone number with your country codes.'
                    });
                    recaptchaWrapperRef.current.innerHTML = `<div style="display:none" id="recaptcha-container"></div>`;
                });
        } else {
            setResponseText({
                successMsg: '',
                errorMsg: 'Field shouldn\'t be empty.'
            });
        }
    }

    const verifyotp = () => {
        const code = otpCode;
        if (code) {
            setLoading(true);
            window.confirmationResult.confirm(code).then((result) => {
                const user = result.user;
                setResponseText({
                    successMsg: 'User Verified. Redirecting to Dashboard...',
                    errorMsg: ''
                });
                setLoading(false);
                setTimeout(() => {
                    localStorage.setItem('token', JSON.stringify(user));
                    window.dispatchEvent(new Event("storage"));
                }, 1000)

            }).catch((error) => {

                setResponseText({
                    successMsg: '',
                    errorMsg: 'OTP Verification code didn\'t match'
                });
                setLoading(false);
            });
        } else {
            setResponseText({
                successMsg: '',
                errorMsg: 'Field shouldn\'t be empty.'
            });
        }
    }

    const resendOtp = () => {
        const phoneNumber = userInput.phone_number;
        configureCaptcha();
        const appVerifier = window.recaptchaVerifier;
        const phone = '+' + phoneNumber;

        signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setResponseText({
                successMsg: 'OTP code has been sent to your mobile.',
                errorMsg: ''
            });

            setCounter(59);

        }).catch((error) => {
            setLoading(false);
            recaptchaWrapperRef.current.innerHTML = `<div style="display:none" id="recaptcha-container"></div>`;
        });
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.setModal}
                // onClose={props.closeModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.setModal}>

                    {otpBlock ?

                        <Box sx={style}>
                            <Typography
                                sx={{
                                    marginBottom: '10px',
                                    color: '#444',
                                    padding: '8px 15px',
                                    fontSize: '16px',
                                    backgroundColor: '#eee'
                                }}
                                id="transition-modal-title" variant="h6" component="h2">
                                Please enter your 6 digit OTP Code
                            </Typography>

                            <OtpInput
                                onChange={handleOTP}
                                numInputs={6}
                                value={otpCode}
                                inputStyle={{
                                    width: "3rem",
                                    height: "3rem",
                                    margin: "0 5px",
                                    fontSize: "2rem",
                                    borderRadius: 4,
                                    border: "1px solid rgba(0,0,0,0.2)"
                                }}
                                separator={
                                    <span>&nbsp;</span>
                                }
                            />
                            <div ref={recaptchaWrapperRef}>
                                <div style={{ 'display': 'none' }} id="recaptcha-container"></div>
                            </div>
                            <div className="btn-wrap">
                                <Button
                                    onClick={verifyotp}
                                    style={{
                                        marginTop: '15px',
                                        width: '100%',
                                        pointerEvents: loading ? 'none' : ''
                                    }}
                                    size="large" variant="contained"> {loading && <CircularProgress style={{
                                        color: 'white',
                                        height: '20px',
                                        width: '20px',
                                        marginRight: '10px',
                                    }} disableShrink />} Verify Code</Button>
                            </div>
                            <div style={{
                                textAlign: "center",
                                marginTop: "15px"
                            }} 
                            className="timer">
                                {counter > 0 ? 
                                <>
                                    Code expires in <span style={{color:"#1976d2"}} >00:{("0"+counter).slice(-2)}</span>
                                </> 
                                :   
                                <span style={{cursor:"pointer",color:"#1976d2"}} onClick={resendOtp}>Resend Code</span>
                                }
                            </div>
                            <div className="response" style={{ marginTop: '12px' }}>
                                {responseText.successMsg &&
                                    <Alert style={{ padding: '0 16px' }} className='alert-msg' severity="success">{responseText.successMsg}</Alert>
                                }
                                {responseText.errorMsg &&
                                    <Alert style={{ padding: '0 16px' }} className='alert-msg' severity="error">{responseText.errorMsg}</Alert>
                                }
                            </div>
                            <div onClick={props.closeModal}
                                className="close-btn" style={{
                                    position: 'absolute',
                                    top: '-12px',
                                    right: '-12px',
                                    cursor: 'pointer',
                                    height: '35px',
                                    width: '35px',
                                    borderRadius: '50%',
                                    lineHeight: '50px',
                                    textAlign: 'center',
                                    background: '#3e98c7',
                                    color: 'white'
                                }}>
                                <CloseSharpIcon />
                            </div>
                        </Box>
                        :
                        <Box sx={style}>
                            <Typography
                                sx={{
                                    marginBottom: '10px',
                                    color: '#444',
                                    padding: '8px 15px',
                                    fontSize: '16px',
                                    backgroundColor: '#eee'
                                }}
                                id="transition-modal-title" variant="h6" component="h2">
                                Please enter your mobile number
                            </Typography>
                            <TextField
                                sx={inputStyle}
                                type="number"
                                name="phone_number"
                                onChange={handleChange}
                                id="standard-input"
                                label="Phone Number"
                                variant="standard"
                            />
                            <div ref={recaptchaWrapperRef}>
                                <div style={{ 'display': 'none' }} id="recaptcha-container"></div>
                            </div>
                            <div className="btn-wrap">
                                <Button
                                    onClick={onSignInSubmit}
                                    style={{
                                        marginTop: '15px',
                                        width: '100%',
                                        pointerEvents: loading ? 'none' : ''
                                    }}
                                    size="large" variant="contained"> {loading && <CircularProgress style={{
                                        color: 'white',
                                        height: '20px',
                                        width: '20px',
                                        marginRight: '10px',
                                    }} disableShrink />} Send Code</Button>
                            </div>
                            <div className="response" style={{ marginTop: '12px' }}>
                                {responseText.successMsg &&
                                    <Alert style={{ padding: '0 16px' }} className='alert-msg' severity="success">{responseText.successMsg}</Alert>
                                }
                                {responseText.errorMsg &&
                                    <Alert style={{ padding: '0 16px' }} className='alert-msg' severity="error">{responseText.errorMsg}</Alert>
                                }
                            </div>
                            <div onClick={props.closeModal}
                                className="close-btn" style={{
                                    position: 'absolute',
                                    top: '-12px',
                                    right: '-12px',
                                    cursor: 'pointer',
                                    height: '35px',
                                    width: '35px',
                                    borderRadius: '50%',
                                    lineHeight: '50px',
                                    textAlign: 'center',
                                    background: '#3e98c7',
                                    color: 'white'
                                }}>
                                <CloseSharpIcon />
                            </div>
                        </Box>
                    }
                </Fade>
            </Modal>

        </div>
    );
}
