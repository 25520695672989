import "./profile.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"

const Profile = () => {
    const user = JSON.parse(window.localStorage.getItem('token'));
    console.log(user)
    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <div className="profile">
                    <div className="top">
                        <div className="left">
                            <div className="editButton">Edit</div>
                            <h1 className="title">Information</h1>
                            <div className="item">
                                {user.photoURL ?
                                    <img
                                        referrerPolicy="no-referrer"
                                        src={user.photoURL}
                                        alt=""
                                        className="itemImg"
                                    /> :
                                    <img
                                        src="https://i.pravatar.cc/500"
                                        alt=""
                                        className="itemImg"
                                    />
                                }
                                <div className="details">
                                    <h1 className="itemTitle">{user.displayName?user.displayName:'Anonymous User'}</h1>
                                    <div className="detailItem">
                                        <span className="itemKey">Email:</span>
                                        <span className="itemValue">{user.email}</span>
                                    </div>
                                    <div className="detailItem">
                                        <span className="itemKey">Phone:</span>
                                        <span className="itemValue">+1 2345 67 89</span>
                                    </div>
                                    <div className="detailItem">
                                        <span className="itemKey">Address:</span>
                                        <span className="itemValue">
                                            Elton St. 234 Garden Yd. NewYork
                                        </span>
                                    </div>
                                    <div className="detailItem">
                                        <span className="itemKey">Country:</span>
                                        <span className="itemValue">USA</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="right">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile